@tailwind base;
@tailwind components;
@tailwind utilities;



.MuiSelect-select,
.MuiInputBase-root,
.MuiOutlinedInput-input {
  height: 40px !important;
  margin: 5px 0 !important;
}

.input:-internal-autofill-selected {
  background-color: red !important;
}

input:-internal-autofill-selected {
  background-color: red !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
  -webkit-text-fill-color: #333;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
}
