.job-status-form-conatiner {
  /* width: 100%; */
  width: 500px;
}
.close-btn {
  display: flex;
  justify-content: flex-end;
}
.Heading {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.detail-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px 0;
}
.box-user {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.userDetails {
  cursor: pointer;
}

.userDetails:hover {
  color: red !important;
}

