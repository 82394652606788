@tailwind base;
@tailwind components;
@tailwind utilities;



.table-scrollable-body {
    max-height: 600px; /* Adjust as needed */
    overflow-y: auto;
  }
  
  .table-scrollable-body table {
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-scrollable-body th,
  .table-scrollable-body td {
    padding: 8px; /* Adjust as needed */
    border-bottom: 1px solid #ddd; /* Optional: Add borders to separate rows */
  }
  