.box-height {
  min-height: 40px;
  margin-bottom: 10px;
}

.input-height {
  height: 100px;
}

.heading-width {
  width: 300px;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
    
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}
